import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { trackEvent } from "../analytics";

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const handleNavigation = (destination) => {
      trackEvent("Navigation", `Clicked ${destination} Link`, "Navbar");
  };

  return (
    <motion.nav
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`flex justify-end p-4 fixed top-0 w-full z-50 transition-all ${
        isScrolled ? "bg-gray-900 bg-opacity-80" : "bg-transparent"
      }`}
    >
      <ul className="flex space-x-6 text-lg font-semibold text-gray-100">
        <li>
          <Link
            to="/"
            onClick={() => handleNavigation("Home")}
            className="hover:text-blue-400 transition-colors duration-300"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            onClick={() => handleNavigation("About")}
            className="hover:text-blue-400 transition-colors duration-300"
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            onClick={() => handleNavigation("Contact")}
            className="hover:text-blue-400 transition-colors duration-300"
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </motion.nav>
  );
}

export default Navbar;

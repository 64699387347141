import ReactGA from "react-ga4";

export const initializeAnalytics = () => {
  ReactGA.initialize("G-8XFMDCWP2Q"); // Replace with your actual Tracking ID
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";

function About() {
  return (
    <div className="bg-gray-900 text-gray-200 min-h-screen flex flex-col justify-between">
      {/* About Section */}
      <div className="container mx-auto py-16 px-8">
        <h1 className="text-5xl font-bold text-center mb-8">About Me</h1>
        <p className="text-lg text-center max-w-4xl mx-auto mb-8">
          Hi, I’m <strong>John Doe</strong>, the founder of <strong>AM Self Storage</strong>.
          I’ve spent the last 10 years working with property owners across the country, helping them
          sell their self-storage facilities quickly and easily. This isn’t just a business for me—it’s a passion.
        </p>
        <p className="text-lg text-center max-w-4xl mx-auto mb-8">
          I understand that selling a property can be a big decision, and I’m here to make the process as
          smooth and stress-free as possible. When you work with me, you’re not dealing with a big company—you’re
          dealing with a person who cares about your goals and understands the value of personal connections.
        </p>

        {/* Mission Statement */}
        <div className="bg-gray-800 py-8 px-6 rounded-md shadow-lg max-w-4xl mx-auto text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Why I Do This</h2>
          <p className="text-lg">
            My mission is simple: to help self-storage owners like you transition to the next chapter of your lives.
            I believe in fair offers, transparent communication, and a process that respects your time and needs.
          </p>
        </div>

        {/* Personal Story Section */}
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">A Little About Me</h2>
          <p className="text-lg mb-8">
            I started this journey after working in real estate for several years. I realized that many property owners
            were frustrated with the traditional sales process—dealing with brokers, repairs, and endless negotiations.
            That’s when I decided to create a better solution.
          </p>
          <p className="text-lg mb-8">
            Outside of work, I enjoy exploring local coffee shops, spending time with my family, and taking weekend
            road trips to discover new places. I believe that life is all about building meaningful relationships and
            finding joy in the little things.
          </p>
        </div>

        {/* Social Media Links */}
        <div className="text-center mt-12">
          <h2 className="text-3xl font-bold mb-6">Connect with Me</h2>
          <div className="flex justify-center space-x-8">
            {/* Facebook */}
            <a
              href="https://www.facebook.com/your-profile"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-400 transition-all duration-300"
            >
              <FontAwesomeIcon icon={faFacebook} size="3x" />
            </a>
            {/* LinkedIn */}
            <a
              href="https://www.linkedin.com/in/your-profile"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-300 transition-all duration-300"
            >
              <FontAwesomeIcon icon={faLinkedin} size="3x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

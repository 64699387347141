import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileAlt,
    faHandshake,
    faClipboardCheck,
    faBalanceScale,
    faTools,
    faClock,
  } from "@fortawesome/free-solid-svg-icons";



function Home() {
  // Carousel settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  const images = [
    "/hero.jpg", // Replace with actual image paths
    "/hero2.jpg",
    "/hero3.jpg",
    // "/image4.jpg",
  ];

  return (
    <div>
      {/* Hero Section with Carousel */}
      <section className="hero h-screen relative">
        <div className="absolute inset-0">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="h-screen">
                <div
                  className="h-screen w-full"
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
        <div className="relative z-20 flex flex-col items-center justify-center h-full text-center text-white">
          <h1 className="text-5xl font-bold">Sell Your Self-Storage Facility with Ease</h1>
          <p className="mt-4 text-xl">
            We specialize in purchasing self-storage facilities nationwide. Experience a stress-free sale with our expert team.
          </p>
          <div className="mt-8">
            <a
              href="/about"
              className="bg-white text-blue-600 px-6 py-3 rounded-md shadow-md font-bold hover:bg-gray-200"
            >
              Learn More
            </a>
            <a
              href="/contact"
              className="ml-4 bg-red-600 text-white px-6 py-3 rounded-md shadow-md font-bold hover:bg-red-500"
            >
              Contact Us
            </a>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
    <section className="how-it-works bg-gray-800 py-16 text-gray-200">
    <div className="container mx-auto text-center">
        <h2 className="text-5xl font-bold mb-10">How It Works</h2>
        <div className="flex flex-wrap justify-center">
        {/* Step 1 */}
        <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faFileAlt} className="text-blue-500 text-9xl mb-6" />
            <h3 className="text-3xl font-semibold mb-4">1. Submit Your Property Details</h3>
            <p className="text-lg">
                Fill out our simple online form with your property details. Provide us with basic
                information like location, size, and current condition of your facility—it takes less than 5 minutes!
            </p>
            </div>
        </div>
        {/* Step 2 */}
        <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faHandshake} className="text-green-500 text-9xl mb-6" />
            <h3 className="text-3xl font-semibold mb-4">2. Receive Your Offer</h3>
            <p className="text-lg">
                Our team will review your details and prepare a fair, no-obligation offer. We'll ensure you get
                the best possible price for your self-storage facility.
            </p>
            </div>
        </div>
        {/* Step 3 */}
        <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faClipboardCheck} className="text-red-500 text-9xl mb-6" />
            <h3 className="text-3xl font-semibold mb-4">3. Close the Deal</h3>
            <p className="text-lg">
                Once you accept our offer, we take care of everything—from paperwork to finalizing the deal.
                You'll have a smooth and stress-free experience.
            </p>
            </div>
        </div>
        </div>
    </div>
    </section>



      {/* Why Choose Us Section */}
    <section className="why-choose-us py-16 bg-gray-900 text-gray-200">
    <div className="container mx-auto text-center">
        <h2 className="text-5xl font-bold mb-10">Why Choose Us</h2>
        <div className="flex flex-wrap justify-center">
        {/* Fair Offers */}
        <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faBalanceScale} className="text-blue-400 text-9xl mb-6" />
            <h3 className="text-3xl font-semibold mb-4">Fair Offers</h3>
            <p className="text-lg">
                We analyze the market and your facility's unique characteristics to provide a competitive and fair offer.
                You can trust us to deliver the best value for your property.
            </p>
            </div>
        </div>
        {/* Hassle-Free */}
        <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faTools} className="text-green-400 text-9xl mb-6" />
            <h3 className="text-3xl font-semibold mb-4">Hassle-Free</h3>
            <p className="text-lg">
                No need to spend time or money on repairs or renovations. We buy your property in its current condition,
                saving you time and effort.
            </p>
            </div>
        </div>
        {/* Fast and Flexible */}
        <div className="w-full md:w-1/3 px-6 mb-12">
            <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faClock} className="text-yellow-400 text-9xl mb-6" />
            <h3 className="text-3xl font-semibold mb-4">Fast and Flexible</h3>
            <p className="text-lg">
                We work on your timeline to ensure the sale process fits your schedule. Need to sell quickly? We can close the deal fast.
                Prefer a slower pace? We've got you covered.
            </p>
            </div>
        </div>
        </div>
    </div>
    </section>



      {/* Call-to-Action Section */}
        <section className="bg-gray-800 py-16 text-white text-center">
        <h2 className="text-4xl font-bold mb-4">Ready to Sell Your Self-Storage Facility?</h2>
        <p className="mb-8 text-lg">Contact us today and take the first step toward a hassle-free sale.</p>
        <a
            href="/contact"
            className="bg-white text-blue-600 px-8 py-4 rounded-md shadow-md text-lg font-bold hover:bg-gray-200"
        >
            Contact Us
        </a>
        </section>
    </div>
  );
}

export default Home;
